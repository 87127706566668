import React, { Fragment } from 'react'

import { orderBy } from 'lodash'

import {
  Box,
  Typography,
  TextField,
  Grid,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'

import { Table, TableRow, TableCell, TableBody } from 'components/Table'

import styles from './styles'

import helpers from 'helpers'

const useStyles = makeStyles(styles)

const LegalFrameworks = ({ data, isLoading }) => {
  const classes = useStyles()
  return (
    <>
      <Grid container className={classes.legalFrameworkContainer}>
        <Grid
          className={classes.legalFrameworkTitle}
          item
          xl={12}
          lg={12}
          md={12}
          xs={12}
        >
          <Typography variant="h5">Enquadramento Legal</Typography>
        </Grid>
        {isLoading ? (
          <Box
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box display="flex" alignItems="flex-end" width="100%">
              <Grid container>
                <Grid
                  item
                  className={classes.inputContainer}
                  xl={6}
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    className={classes.topicContainer}
                  >
                    Qual é a base legal do tratamento?
                  </Typography>
                  <TextField
                    label="Base legal"
                    defaultValue={data.legalBase || 'Base legal não encontrada'}
                    multiline
                    rows={6}
                    fullWidth
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  className={classes.inputContainer}
                  xl={6}
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    className={classes.topicContainer}
                  >
                    Legítimo interesse
                  </Typography>
                  <TextField
                    label="Legítimo Interesse (LIA)"
                    defaultValue={
                      data.lia || 'Legitimo interesse não encontrado'
                    }
                    multiline
                    rows={6}
                    fullWidth
                    variant="outlined"
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid
                item
                className={classes.inputContainer}
                xl={4}
                lg={4}
                md={4}
                xs={12}
              >
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  className={classes.topicContainer}
                >
                  Enquadramentos legais
                </Typography>
                <Table
                  size="small"
                  emptyMessage="Nenhum enquadramento legal"
                  isLoading={isLoading}
                  noWrap
                >
                  <TableBody>
                    {orderBy(
                      helpers.dataProcess.orderLegalFrameworks(
                        data?.legalFrameworks,
                      ),
                      'order',
                    ).map((legalFramework) => (
                      <Fragment key={legalFramework?.order}>
                        <TableRow>
                          <TableCell variant="head">
                            {legalFramework?.label}
                          </TableCell>
                        </TableRow>
                        {!isLoading &&
                          legalFramework?.items?.map((legalFrameworkItem) => (
                            <TableRow key={legalFrameworkItem.id}>
                              <TableCell>{legalFrameworkItem?.name}</TableCell>
                            </TableRow>
                          ))}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default LegalFrameworks
