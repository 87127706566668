const LEGITIMATE_INTEREST = 'Legítimo interesse do controlador ou de terceiro'
const FRAUD_PREVENTION_HOLDER_SECURITY =
  'Prevenção à fraude e segurança do titular'
const LEGITIMATE_INTEREST_SHORTNED = 'Legítimo interesse'

const TYPE_PERSONAL_AND_FINANCIAL = 1
const TYPE_SENSITIVE = 2
const TYPE_MINOR = 3
const TYPE_MINOR_SENSITIVE = 4

const TYPE_PERSONAL_AND_FINANCIAL_VALUE = 'personalAndFinancial'
const TYPE_SENSITIVE_VALUE = 'sensitive'
const TYPE_MINOR_VALUE = 'minor'
const TYPE_MINOR_SENSITIVE_VALUE = 'minorSensitive'

const TYPE_PERSONAL_AND_FINANCIAL_LABEL = 'Pessoais'
const TYPE_SENSITIVE_LABEL = 'Pessoais sensíveis'
const TYPE_MINOR_LABEL = 'Crianças e/ou adolescentes pessoais'
const TYPE_MINOR_SENSITIVE_LABEL = 'Crianças e/ou adolescentes sensíveis'

const LABEL_BY_TYPE = {
  [TYPE_PERSONAL_AND_FINANCIAL]: TYPE_PERSONAL_AND_FINANCIAL_LABEL,
  [TYPE_SENSITIVE]: TYPE_SENSITIVE_LABEL,
  [TYPE_MINOR]: TYPE_MINOR_LABEL,
  [TYPE_MINOR_SENSITIVE]: TYPE_MINOR_SENSITIVE_LABEL,
}

const VALUE_BY_TYPE = {
  [TYPE_PERSONAL_AND_FINANCIAL]: TYPE_PERSONAL_AND_FINANCIAL_VALUE,
  [TYPE_SENSITIVE]: TYPE_SENSITIVE_VALUE,
  [TYPE_MINOR]: TYPE_MINOR_VALUE,
  [TYPE_MINOR_SENSITIVE]: TYPE_MINOR_SENSITIVE_VALUE,
}

const legalFrameworks = {
  FRAUD_PREVENTION_HOLDER_SECURITY,
  LEGITIMATE_INTEREST,
  LEGITIMATE_INTEREST_SHORTNED,
  LABEL_BY_TYPE,
  VALUE_BY_TYPE,
}

export default legalFrameworks
