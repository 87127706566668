import constants from 'constants/index'

import { isEmpty, isArray } from 'lodash'

const orderLegalFrameworks = (legalFrameworks) => {
  if (!isArray(legalFrameworks)) return
  let formattedLegalFrameworks = {}

  legalFrameworks.map((legalFramework) => {
    const dataTypeValue =
      constants.legalFrameworks.VALUE_BY_TYPE[legalFramework.dataType]

    if (isEmpty(formattedLegalFrameworks[dataTypeValue])) {
      formattedLegalFrameworks[dataTypeValue] = {
        order: legalFramework.dataType,
        label: constants.legalFrameworks.LABEL_BY_TYPE[legalFramework.dataType],
        items: [legalFramework],
      }
    } else {
      formattedLegalFrameworks[dataTypeValue].items = [
        ...formattedLegalFrameworks[dataTypeValue].items,
        legalFramework,
      ]
    }
    return legalFramework
  })

  return formattedLegalFrameworks
}

export default orderLegalFrameworks
